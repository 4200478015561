import ingredientDistributionIcon from '../../img/ingredient_distribution_icon.png';
import circularityIcon from '../../img/circularity_icon.png';
import overbakedIcon from '../../img/overbaked_icon.png';
import cutIcon from '../../img/cut_icon.png';
import bubblesIcon from '../../img/bubbles_icon.png';
import cheeseDistributionIcon from '../../img/cheese_distribution_icon.png';
import pepperoniIcon from '../../img/pepperoni_original.png';
import cheeseIcon from '../../img/cheese.png';
import worksTestIcon from '../../img/overbaked_icon.png';
import napolitanIcon from '../../img/napolitan.png';
import meatIcon from '../../img/meat.png';
import italianIcon from '../../img/italian.png';
import sweetChiliChickenIcon from '../../img/sweet_chili_chicken.png';
import bbqChickenAndSausageIcon from '../../img/bbq_chicken_and_sausage.png';
import pestoMargaritaIcon from '../../img/pesto_margarita_proxy.png';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const SHIFTS = [
  'AM',
  'PM'
]

const PROBLEMS = {
  ingredient_distribution: {
    name: {
      en: 'Ingredient Distribution',
      es: ''
    },
    icon: ingredientDistributionIcon,
    tip: {
      en: 'Ensure that the toppings are evenly distributed in the correct portions',
      es: 'Asegúrate de que los ingredientes estén distribuidos de manera uniforme'
    }
  },
  circularity: {
    name: {
      en: 'Circularity',
      es: ''
    },
    icon: circularityIcon,
    tip: {
      en: 'Use well managed dough with correct technique to insure proper shape',
      es: 'Utiliza una masa bien gestionada con la técnica correcta para asegurar una forma adecuada'
    }
  },
  overbaked: {
    name: {
      en: 'Overbaked',
      es: ''
    },
    icon: overbakedIcon,
    tip: {
      en: 'Top causes of improper baking. Oven temp & baking time, Dough staging. Loading and unloading. Watery or fatty topping distribution',
      es: 'Revisa la temperatura del horno, tiempo de horneado, etapas de la masa, carga y descarga y distribución de ingredientes líquidos o grasosos'
    }
  },
  cut: {
    name: {
      en: 'Cut',
      es: ''
    },
    icon: cutIcon,
    tip: {
      en: 'Completely Cut pizzas into even slices with proper pressure',
      es: 'Corta las pizzas por completo en porciones uniformes con la presión adecuada'
    }
  },
  bubbles: {
    name: {
      en: 'Bubbles',
      es: ''
    },
    icon: bubblesIcon,
    tip: {
      en: 'Ensure proper dough handling, oven tending and docking procedures',
      es: 'Asegúrate de manipular la masa correctamente y atender el horno para evitar burbujas'
    }
  },
  pizza_size: {
    name: {
      en: 'Pizza Size',
      es: ''
    },
    icon: circularityIcon,
    tip: {
      en: 'Use well managed dough with correct technique to insure proper size',
      es: 'Utiliza una masa bien gestionada con la técnica correcta para asegurar un tamaño adecuado'
    }
  },
  cheese_distribution: {
    name: {
      en: 'Cheese Distribution',
      es: ''
    },
    icon: cheeseDistributionIcon,
    tip: {
      en: 'Evenly distribute correct portions of cheese across the entire designated area',
      es: 'Distribuye de manera uniforme porciones correctas de queso en toda el área designada'
    }
  },
}

const getReportName = (date, venue) => {
  let endDate = new Date(new Date(date).getTime() + 6 * 24 * 60 * 60 * 1000);
  return `${venue} ${MONTHS[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()} - ${MONTHS[endDate.getMonth()] + ' ' + endDate.getDate() + ', ' + endDate.getFullYear()}`;
}

const getMonthlyReportName = (date, company, region) => {
  return `${company}${region ? ` ${region}` : ''} ${MONTHS[date.getMonth()]}`;
}

const paginator = (firstPageRows, otherPagesRows, lastPageMin, data) => {
  let x = firstPageRows;
  let y = otherPagesRows;
  let notPage1 = (data.length - x);
  let lastPageRows = notPage1 % y;
  while (lastPageRows < lastPageMin && lastPageRows > 0 && x > 1) {
    x -= 1;
    y -= 1;
    notPage1 = (data.length - x);
    lastPageRows = notPage1 % y;
  }
  let storesPerPage = Math.min(x, data.length);
  let startIndex = 0;
  let pages = [];
  while (startIndex + storesPerPage <= data.length && storesPerPage !== 0) {
    pages.push(data.slice(startIndex, startIndex + storesPerPage));
    startIndex = startIndex + storesPerPage;
    storesPerPage = Math.min(y, data.length - startIndex);
  }
  return pages;
}

const getStandardCards = (pizzas) => {
  let cards = [],
    specialties = {},
    featureBest = undefined,
    featureWorst = undefined;
  for (let [index, pizza] of pizzas.entries()) {
    if (pizza.specialty === 'other') continue;
    specialties[pizza.specialty] = 1;
    if (pizza.show_on_report) {
      let day = pizza.weekday;
      let card = {
        index: index,
        score: pizza.score,
        featured: pizza.featured,
        specialty: pizza.specialty,
        img_url: pizza.img_url,
        weekday: pizza.weekday,
        shift: pizza.shift,
        standard: pizza.standard,
        main_problem: !Array.isArray(pizza.main_problem)? [pizza.main_problem]: pizza.main_problem,
        standardOrder: pizza.standard === 'to_standard' ? 0 : 1,
        dayOrder: day === 'Sunday' ? 0 : day === 'Monday' ? 1 : day === 'Tuesday' ? 2 : day === 'Wednesday' ? 3 : day === 'Thursday' ? 4 : day === 'Friday' ? 5 : 6,
        shiftOrder: pizza.shift === 'AM' ? 0 : 1,
        timeStamp: pizza.time_stamp
      };
      cards.push(card)
      if (pizza.featured && pizza.standard === 'to_standard') featureBest = card;
      if (pizza.featured && pizza.standard === 'not_to_standard') featureWorst = card;
    }
  }
  specialties = Object.keys(specialties)
  specialties.sort().reverse();
  cards.sort(function (a, b) {
    if (specialties.indexOf(a.specialty) < specialties.indexOf(b.specialty)) {
      return -1;
    } else if (specialties.indexOf(a.specialty) > specialties.indexOf(b.specialty)) {
      return 1;
    } else {
      if (a.standardOrder < b.standardOrder) {
        return -1;
      } else if (a.standardOrder > b.standardOrder) {
        return 1;
      } else {
        if (a.dayOrder < b.dayOrder) {
          return -1;
        } else if (a.dayOrder > b.dayOrder) {
          return 1;
        } else {
          if (a.shiftOrder < b.shiftOrder) {
            return -1;
          } else if (a.shiftOrder > b.shiftOrder) {
            return 1;
          } else {
            if (a.timeStamp < b.timeStamp) {
              return -1;
            } else if (a.timeStamp > b.timeStamp) {
              return 1;
            } else {
              return 0
            }
          }
        }
      }
    }
  });
  for (let index = 0; index < cards.length; index++) {
    cards[index].cardIndex = index + 1;
  }
  return {
    cards: cards,
    specialties: specialties,
    featureBest: featureBest,
    featureWorst: featureWorst
  };
}

const getScorelessCards = (pizzas, pictureCount, reportControl = false) => {
  let cards = {},
    specialties = {};
  for (let [index, pizza] of pizzas.entries()) {
    if (pizza.show_on_report) {
      specialties[pizza.specialty] = 1;
      if (!cards[pizza.weekday]) cards[pizza.weekday] = {};
      if (!cards[pizza.weekday][pizza.shift]) cards[pizza.weekday][pizza.shift] = [];
      cards[pizza.weekday][pizza.shift].push({
        index: index,
        img_url: pizza.img_url,
        weekday: pizza.weekday,
        shift: pizza.shift,
        timeStamp: pizza.time_stamp
      });
    }
  }
  for (let day of Object.keys(cards)) {
    for (let shift of Object.keys(cards[day])) {
      cards[day][shift].sort(function (a, b) {
        if (a.timeStamp < b.timeStamp) {
          return -1;
        } else if (a.timeStamp > b.timeStamp) {
          return 1;
        } else {
          return 0;
        }
      })
    }
  }
  for (let day of Object.keys(cards).sort((a, b) => DAYS.indexOf(a) - DAYS.indexOf(b))) {
    for (let shift of Object.keys(cards[day]).sort((a, b) => SHIFTS.indexOf(a) - SHIFTS.indexOf(b))) {
      for (let i = 0; i < cards[day][shift].length; i++) {
        cards[day][shift][i].cardIndex = pictureCount;
        pictureCount += 1;
      }
    }
  }
  if (reportControl) {
    return {
      cards: cards,
      totalPizzas: pictureCount - 1
    }
  }
  let temp = [],
    tempd = {},
    count = 0;
  for (let day of Object.keys(cards).sort((a, b) => DAYS.indexOf(a) - DAYS.indexOf(b))) {
    tempd[day] = {}
    for (let shift of Object.keys(cards[day]).sort((a, b) => SHIFTS.indexOf(a) - SHIFTS.indexOf(b))) {
      if (cards[day][shift]) {
        count += Math.ceil(cards[day][shift].length / 5);
        if (!tempd[day]) tempd[day] = {};
        tempd[day][shift] = cards[day][shift]
      }
      if (count >= 4) {
        let tempshift = undefined;
        if (count > 4) {
          tempshift = {}
          tempshift[day] = {}
          tempshift[day][shift] = tempd[day][shift]
          delete tempd[day][shift]
        }
        count = 0;
        temp.push(tempd)
        tempd = {}
        if (tempshift) {
          tempd = tempshift
          count += Math.ceil(tempd[day][shift].length / 5);
        }
      }
    }
  }
  if (count < 4 && count > 0) {
    temp.push(tempd)
  }
  return {
    cards: temp,
    totalPizzas: pictureCount - 1
  }
}

const reportPizzasToCards = (report, scoreless = false, reportControl = false) => {
  let standardCards;
  let scorelessCards;
  if (!scoreless) {
    standardCards = getStandardCards(report.pizzas);
    scorelessCards = report.standard_scoreless_report === 1 ? getScorelessCards(report.pizzas_scoreless, standardCards.cards.length + 1, reportControl) : {totalPizzas: 0, cards: []};
    return {
      ...standardCards,
      cardsScoreless: scorelessCards.cards,
      totalPizzas: scorelessCards.totalPizzas
    }
  }
  else {
    scorelessCards = getScorelessCards(report.pizzas, 1, reportControl);
    return { cardsScoreless: scorelessCards.cards, totalPizzas: scorelessCards.totalPizzas }
  }
}

const getSimilarPizzas = (card, report, type) => {
  let pizzasField = [1, 2].includes(type) ? 'pizzas' : 'pizzas_scoreless';
  let similarPizzas = [];
  for (let [index, pizza] of report[pizzasField].entries()) {
    if (pizza.show_on_report) continue;
    if (type === 1 && pizza.standard !== card.standard) continue;
    if (type === 1 && pizza.specialty !== card.specialty) continue;
    if ([2, 3].includes(type) && pizza.shift !== card.shift) continue;
    if ([2, 3].includes(type) && pizza.weekday !== card.weekday) continue;
    similarPizzas.push({
      replace: card.index,
      score: pizza.score,
      index: index,
      featured: pizza.featured,
      specialty: pizza.specialty,
      img_url: pizza.img_url,
      weekday: pizza.weekday,
      shift: pizza.shift,
      standard: pizza.standard,
      mainProblem: !Array.isArray(pizza.main_problem)? [pizza.main_problem]: pizza.main_problem,
      timeStamp: pizza.time_stamp,
      scoreless: [2, 3].includes(type) ? 1 : 0,
      pizzasField: pizzasField
    });
  }
  similarPizzas.sort(function (a, b) {
    if ((a.score < b.score && card.standard === 'not_to_standard') || (a.score > b.score && card.standard === 'to_standard')) {
      return -1;
    } else if ((a.score > b.score && card.standard === 'not_to_standard') || (a.score < b.score && card.standard === 'to_standard')) {
      return 1;
    } else {
      return 0
    }
  });
  return similarPizzas;
}

const colorVariation = (values, index) => {
  if (index === 0 || [undefined, null].includes(values[index]) || [undefined, null].includes(values[index - 1])) return 'white';
  let prev = (values[index - 1] * 100).toFixed(0);
  let current = (values[index] * 100).toFixed(0);
  if (current > prev) return '#e1efd8';
  if (current < prev) return '#fbcfd0';
  return 'white';
}

const SPECIALTIES_ICONS = {
  'pepperoni': pepperoniIcon,
  'cheese': cheeseIcon,
  'works_test': worksTestIcon,
  'napolitana': napolitanIcon,
  'meat': meatIcon,
  'pepperoni_PH': pepperoniIcon,
  'italiana': italianIcon,
  'italiana_napolitana_PH': italianIcon,
  '1spec_pepperoni': pepperoniIcon,
  'PH_TZ_pepperoni': pepperoniIcon,
  'PH_TZ_bbq_chicken_and_sausage': bbqChickenAndSausageIcon,
  'PH_TZ_sweet_chili_chicken': sweetChiliChickenIcon,
  'PH_BR_pepperoni': pepperoniIcon,
  'PH_BR_brasileira': meatIcon,
  'PH_BR_frango_requeijao': sweetChiliChickenIcon,
  'Melt_pepperoni': pepperoniIcon,
  'Melt_pesto_margarita': pestoMargaritaIcon,
}

const COLORS = [
  '#4472c4',
  '#ed7d31',
  '#a5a5a5',
  '#ffc000',
  '#5b9bd5',
  '#71ad47',
  '#284679',
  '#f54df7',
  '#77f2ff',
  '#fdc00e',
  '#11111B',
  '#ff0000'
]

const SYMBOLS = [
  'square',
  'hexagon',
  'diamond',
  'triangle-left',
  'triangle-right',
  'x',
  'cross',
  'circle',
  'y-up',
  'y-down',
  'star',
  'diamond-tall',
  'star-triangle-up'
]

export {
  MONTHS,
  DAYS,
  SHIFTS,
  PROBLEMS,
  SPECIALTIES_ICONS,
  COLORS,
  SYMBOLS,
  getReportName,
  getMonthlyReportName,
  paginator,
  reportPizzasToCards,
  getSimilarPizzas,
  colorVariation
}
