const UITEXT = {
    en: {
        t0: 'Weekly Pizza Quality Report',
        t1: 'Weekly Performance',
        t2: 'Tips to Improve Your Pizzas',
        t3: "Last Week's Production",
        t4: 'Pizzas To Standard Trend',
        t5: 'Weekly Quality Ranking - Pizzas To Standard',
        t6: 'Stores Evolution',
        t8: "Last Week's Production (Scoreless)",
        d1: 'Insights from 250 Pizzas per week',
        d5: "Papa John's average for stores with over three months of data",
        d6: '(% Pizzas To Standard | Chart)',
        d7: '(% Pizzas to standard | Table)',
        store: 'Store',
        week: 'Week',
        report: 'Report',
        toStandard: 'To Standard',
        totalProduction: 'Total Production',
        delta: '∆ vs Last report',
        lunch: 'Lunch',
        dinner: 'Dinner',
        average: 'Average',

        January: 'January',
        February: 'February',
        March: 'March',
        April: 'April',
        May: 'May',
        June: 'June',
        July: 'July',
        August: 'August',
        September: 'September',
        October: 'October',
        November: 'November',
        December: 'December',

        Sunday: 'Sunday',
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',

        mt0: 'Monthly Quality Tracking',
        mt1: 'Company performance',
        mt2: 'Stores ranking',
        mt3: 'Shifts performance',
        mt4: 'Stores evolution',
        mt6: 'Ranking of the most common pizza problems',
        mt7: 'Evolution of the most common pizza problems',
        mt8: 'Featured images',
        md1: '(% Pizzas to standard accumulated in the last month)',
        md2: '(% Pizzas to standard accumulated in the last month)',
        md3: '(% Pizzas to standard - Shift change at 5:00 PM)',
        md4: '(% Pizzas to standard | Chart)',
        md5: '(% Pizzas to standard | Table)',
        md6: '(% of times each attribute appeared)',
        md7: '(monthly average attribute occurrences per store)',
        md8: '(Examples of to standard and not to standard pizzas)',

        noData: 'No Data',
        percentageToStandard: '% Pizzas to standard',
        month: 'Month',
        storesInReport: '# Stores in report',
        reviewedPizzas: 'Reviewed Pizzas',
        pizzasToStandard: 'Pizzas to standard',
        numberOfStores: 'Number of stores',
    },
    es: {
        t0: 'Informe Semanal de la Calidad de la Pizza',

        January: 'Enero',
        February: 'Febrero',
        March: 'Marzo',
        April: 'Abril',
        May: 'Mayo',
        June: 'Junio',
        July: 'Julio',
        August: 'Agosto',
        September: 'Septiembre',
        October: 'Octubre',
        November: 'Noviembre',
        December: 'Diciembre',

        Sunday: 'Domingo',
        Monday: 'Lunes',
        Tuesday: 'Martes',
        Wednesday: 'Miercoles',
        Thursday: 'Jueves',
        Friday: 'Viernes',
        Saturday: 'Sábado',
    }
}

const getCorrectUiText = (id, language) => {
    if (UITEXT[language] && UITEXT[language][id]) return UITEXT[language][id];
    return UITEXT.en[id] ?? 'ERR';
}

export {
    getCorrectUiText
}